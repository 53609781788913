.confirmationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(to right, #b386e3, #2575fc); 
    padding: 1rem;
  }
  
  .confirmationCard {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
    text-align: center;
  }
  
  .confirmationHeading {
    font-size: 2rem;
    color: #10b981;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .confirmationMessage {
    font-size: 1.1rem;
    color: #4b5563;
    margin-bottom: 2rem;
  }
  
  .bookingDetails {
    text-align: left;
    margin-bottom: 2rem;
  }
  
  .detailRow {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .detailLabel {
    font-weight: 600;
    color: #1f2937;
  }
  
  .detailValue {
    font-weight: 500;
    color: #4b5563;
  }
  
  .homeButton {
    background-color: #3b82f6;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .homeButton:hover {
    background-color: #2563eb;
  }
  
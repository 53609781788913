/* Spinner Styles */
.spinner {
    width: 50px;
    height: 50px;
    border: 6px solid rgba(0, 0, 0, 0.1);
    border-top-color: #3498db; /* Primary color for the spinner */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  /* Overlay Styles */
  .spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  /* Spinner Animation */
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
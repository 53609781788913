/* Drawer container */
.drawer {
  position: fixed;
  top: 0;
  right: -100%; /* Hidden by default */
  width: 400px;
  height: 100%;
  background-color: #e0e5fc; /* Light blue branding color */
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  transition: right 0.3s ease;
  z-index: 1050;
  display: flex;
  flex-direction: column; /* To position logout button at bottom */
}

/* Open state */
.drawer.open {
  right: 0; /* Slide into view */
}

/* Drawer header */
.drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #4A67E6; /* Branding blue */
  color: white;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

/* Close button */
.close-btn {
  background: transparent; /* Make background transparent */
  border: none; /* Remove border */
  font-size: 1.5rem;
  color: white; /* Use white color */
  cursor: pointer;
}

/* Drawer body */
.drawer-body {
  padding: 20px;
  flex: 1; /* Take up available space */
}

/* Logout button */
.logout-btn {
  background-color: #FF0000; /* Branding red */
  color: white;
  border: none;
  padding: 15px;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  text-align: center;
  border-top: 1px solid #ddd;
}

.logout-btn:hover {
  background-color: #cc0000; /* Darker red */
}

/* Header view picture */
.header-view-pic-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.header-view-profile-pic {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 3px solid #4A67E6; /* Branding blue */
  object-fit: cover;
}

/* Profile picture in drawer */
.profile-pic-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.profile-pic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid #4A67E6; /* Branding blue */
  object-fit: cover;
}

.profile-options{
  display:flex;
}

/* Profile details */
.profile-details {
  margin-bottom: 20px;
}

.profile-details p {
  margin: 5px 0;
  font-size: 0.95rem;
  color: #333;
}

/* Options list */
.options-list {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: right;

}

.option-item {
  padding: 10px 15px;
  margin: 5px 0;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
  text-align: center;
}

.option-item:hover {
  color: #fff;
  text-decoration: underline;
}

/* Overlay */
.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1049;
}

/* Responsive Design */
@media (max-width: 768px) {
  .drawer {
    width: 100%; /* Full width on small screens */
  }
}

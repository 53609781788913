/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

/* Modal Container */
.modal-container {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  width: 90%;
  max-width: 500px;
  padding: 25px 20px;
  position: relative;
  animation: modalShow 0.3s ease-out;
}

/* Modal Animation */
@keyframes modalShow {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Close Button */
.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 2rem;
  color: #555;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.2s ease;
  z-index: 1060;
}

.modal-close:hover {
  color: #ff1744;
  transform: scale(1.1);
}

/* Modal Content */
.modal-content {
  max-height: 70vh; /* Limit modal height for long content */
  overflow-y: auto; /* Add scrolling for overflowing content */
  color: #333;
  font-size: 1rem;
  line-height: 1.6;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .modal-container {
    width: 95%;
    padding: 20px;
  }

  .modal-close {
    font-size: 1.5rem;
  }
}

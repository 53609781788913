.overlay {
    background-color: rgba(0, 0, 0, 0.6); 
    backdrop-filter: blur(2px); 
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: white;
    padding: 20px;
}

/* Container for content */
.content-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    animation: fadeInContent 2s ease-in-out;
}

/* Gradient heading with improved visibility */
h1.hero-mainheader {
    font-family: 'Poppins', sans-serif; /* Modern sans-serif font */
    font-size: 3rem;
    font-weight: bold;
    cursor: pointer;
    background:  #FF4A4A; /* Brighter gradient for better contrast */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 3px;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
}

/* Subheading with cleaner style and improved visibility */
h2.hero-subheader {
    font-family: 'Roboto', sans-serif; /* Clean sans-serif font */
    font-size: 2rem;
    font-weight: 300;
    color: #FF4A4A !important; /* Brighter red for contrast */
    margin-bottom: 1rem;
    text-transform: capitalize;
    letter-spacing: 2px;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6); /* Subtle shadow for better contrast */
}

/* Paragraphs with elegant spacing */
p {
    font-family: 'Open Sans', sans-serif; /* Minimalistic font */
    font-size: 1rem;
    line-height: 1.8;
    color: #e0e0e0;
    margin-bottom: 1rem;
}

/* Decorative divider */
.divider {
    height: 1px;
    width: 50%;
    background: linear-gradient(90deg, #5671E6, #FF4A4A); /* Brighter gradient */
    margin: 20px auto;
    border-radius: 2px;
}

/* Fade-in content animation */
@keyframes fadeInContent {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Staggered animations */
.animate-stagger:nth-child(1) {
    animation: fadeInContent 1s ease-in-out;
}
.animate-stagger:nth-child(2) {
    animation: fadeInContent 1.2s ease-in-out;
}
.animate-stagger:nth-child(3) {
    animation: fadeInContent 1.4s ease-in-out;
}

/* Logo Container */
.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px; /* Spacing below the logo */
}

/* Logo Image */
.logo-image {
    width: 150px; /* Reduced size for smaller logo */
    height: auto;
    animation: fadeIn 1s ease-in-out; /* Optional animation */
}

/* Animation for Logo (Optional) */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Footer Styling */
.footer {
  background-color: #ffffff; /* White background for footer */
  color: #4A67E6; /* Blue for text */
  padding: 20px 0;
  font-size: 14px;
}

/* Footer Container */
.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
  padding: 0 20px;
}

/* Footer Section */
.footer-section {
  flex: 1;
  min-width: 250px; /* Ensures sections don't collapse too much */
}

.footer-title {
  font-size: 1.2rem;
  margin-bottom: 15px;
  font-weight: bold;
  color: #FF0000; /* Red for section titles */
}

/* Footer Links */
.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin: 10px 0;
}

.footer-section ul li a {
  text-decoration: none;
  color: #4A67E6; /* Blue for links */
  transition: color 0.3s;
}

.footer-section ul li a:hover {
  color: #FF0000; /* Red for hover */
}

/* Social Media Links */
.footer-social {
  display: flex;
  gap: 15px;
}

.footer-social li a {
  text-decoration: none;
  color: #4A67E6; /* Blue for icons */
  font-size: 1.2rem;
  transition: color 0.3s;
}

.footer-social li a:hover {
  color: #FF0000; /* Red for hover state */
}

/* Footer Bottom */
.footer-bottom {
  text-align: center;
  margin-top: 20px;
  font-size: 0.9rem;
  color: #4A67E6; /* Blue for footer bottom text */
}

.footer-bottom p {
  margin: 0;
  color: #4A67E6;
}

/* Divider Line */
.footer hr {
  border: 0;
  height: 1px;
  background: #FF0000; /* Red divider */
  margin: 20px 0;
}

/* Filter Container */
.filter-container {
  max-width: 900px;
  margin: 20px auto;
}

.filter-actions{
  margin-top:48px !important;
}

/* Input Styling */
.form-select {
  border: 1px solid #4A67E6; /* Branding blue */
  transition: all 0.3s ease-in-out;
}

.form-select:focus {
  box-shadow: 0 0 5px rgba(74, 103, 230, 0.5); /* Focus glow effect */
  border-color: #4A67E6;
}

/* Buttons */
.btn-danger {
  background-color: #FF0000 !important; /* Branding red */
  border: none;
}

.btn-danger:hover {
  background-color: #cc0000 !important; /* Darker red on hover */
}

.btn-success {
  background-color: #4A67E6 !important; /* Branding blue */
  border: none;
}

.btn-success:hover {
  background-color: #3A57D0 !important; /* Darker blue on hover */
}

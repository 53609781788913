/* Container for the TimeSelect component */
.time-select-container {
    /* margin: 20px 0; */
    font-family: Arial, sans-serif;
    width: 100%;
  }
  
  /* Label for the select input */
  .time-select-label {
    font-size: 16px;
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
    color: #333;
  }
  
  /* Style for the select dropdown */
  .time-select-dropdown {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    color: #333;
    transition: border-color 0.3s ease;
  }
  
  .time-select-dropdown:focus {
    border-color: #007bff;
  }
  
  .time-select-dropdown:disabled {
    background-color: #e0e0e0;
    cursor: not-allowed;
  }
  
  
/* Stepper Container */
.stepper-container {
  padding: 20px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}

/* Step Wrapper */
.step-wrapper {
  text-align: center;
  cursor: pointer;
  flex: 1;
  position: relative;
  z-index: 2;
}

.step-wrapper:hover .step-circle {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.step-wrapper:hover .step-label {
  color: #FF0000; /* Red branding hover color */
  transition: color 0.3s ease-in-out;
}

/* Step Circle */
.step-circle {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  background: #E0E5FC; /* Light blue for default circle */
  color: #4A67E6; /* Blue branding text color */
  font-weight: bold;
  font-size: 18px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.current .step-circle {
  background: #4A67E6; /* Solid blue for current step */
  color: #fff; /* White text for contrast */
}

.completed .step-circle {
  background: #FF0000; /* Red for completed steps */
  color: #fff; /* White text for contrast */
}

/* Step Label */
.step-label {
  margin-top: 10px;
  font-size: 14px;
  color: #6c757d; /* Neutral gray */
  transition: color 0.3s ease-in-out;
}

.current .step-label {
  color: #4A67E6; /* Blue branding color for the current step label */
}

.completed .step-label {
  color: #FF0000; /* Red branding color for completed step label */
}

/* Step Line Container */
.step-line-container {
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
  z-index: 1;
}

/* Line */
.step-line {
  height: 2px;
  flex: 1;
  background: #E0E5FC; /* Light blue for default line */
  transition: all 0.3s ease-in-out;
}

.step-line.completed {
  background: #FF0000; /* Red for completed line */
}

/* Dot */
.step-dot {
  width: 12px;
  height: 12px;
  background: #E0E5FC; /* Light blue for default dot */
  border-radius: 50%;
  margin-left: -6px; /* Center the dot */
  transition: all 0.3s ease-in-out;
}

.step-dot.completed {
  background: #FF0000; /* Red for completed dot */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .step-label {
    font-size: 12px;
  }
  .step-circle {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
  }
  .step-dot {
    width: 8px;
    height: 8px;
    margin-left: -4px;
  }
}

/* Section Background */
.home-numbers-section {
  background: linear-gradient(to right, #ffffff, #e0e8f9); /* White to light blue gradient */
  padding: 60px 20px;
  font-family: "Roboto", sans-serif;
}

/* Number Box Styling */
.number-box {
  background: rgba(255, 255, 255, 0.9); /* Slightly opaque white for a clean look */
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px; /* Ensures uniform height for all cards */
}

.number-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  background-color: #d3defc; /* Subtle light blue background for hover state */
}

/* Number Styling */
.number {
  font-size: 3.5rem;
  font-weight: bold;
  margin: 0;
  color: #FF0000 !important; /* Red for the numbers */
  font-family: "Playfair Display", serif; /* Elegant serif font */
  line-height: 1.2; /* Adjust line height for visual balance */
}

/* Label Styling */
.label {
  font-size: 1.1rem;
  color: #4A67E6; /* Blue for the label text */
  text-transform: uppercase;
  margin-top: 10px;
  text-align: center; /* Ensures alignment in case of long text */
}

/* Responsive Design */
@media (max-width: 768px) {
  .number {
      font-size: 2.5rem;
  }

  .label {
      font-size: 1rem;
  }
}

/* Loader Overlay */
.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  
  .loader-overlay.visible {
    opacity: 1;
    visibility: visible;
  }
  
  /* Three Dot Loader */
  .three-dot-loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .dots {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  
  .dots.small .dot {
    width: 6px;
    height: 6px;
  }
  
  .dots.medium .dot {
    width: 12px;
    height: 12px;
  }
  
  .dots.large .dot {
    width: 18px;
    height: 18px;
  }
  
  .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #3498db;
    animation: bounce 1.5s infinite;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes bounce {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  
  .loader-message {
    margin-top: 10px;
    font-size: 14px;
    color: #fff;
    text-align: center;
  }
  
  
/* Section Wrapper */
.contact-info-section {
    background: linear-gradient(to bottom, #ffffff, #e0e8f9); /* White to light blue gradient */
    padding: 40px 20px;
    font-family: "Roboto", sans-serif;
}

/* Info Box Styling */
.info-box {
    background: #ffffff; /* Clean white background */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

/* Title Styling */
.info-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #4A67E6; /* Blue for titles */
    margin-bottom: 10px;
    text-transform: uppercase;
}

/* Detail Text */
.info-detail {
    font-size: 1rem;
    color: #333333; /* Dark gray for readability */
    margin-bottom: 5px;
    line-height: 1.5;
}

/* Note Text */
.info-note {
    font-size: 0.9rem;
    color: #FF0000; /* Red for emphasis */
    font-style: italic;
    margin-bottom: 10px;
}

/* Divider Line */
.info-box hr {
    border: 0;
    height: 1px;
    background: #4A67E6; /* Blue divider */
    margin: 20px 0;
}

/* Responsive Design */
@media (max-width: 768px) {
    .info-title {
        font-size: 1.25rem;
    }

    .info-detail {
        font-size: 0.95rem;
    }
}

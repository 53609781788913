/* Popup Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent dark background */
  backdrop-filter: blur(8px); /* Adds a blur effect */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

/* Popup Container */
.popup-container {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3); /* Subtle shadow for elevation */
  width: 90%;
  max-width: 500px;
  padding: 20px;
  position: relative;
  text-align: center;
  animation: fadeIn 0.3s ease-out;
}

/* Fade In Animation */
@keyframes fadeIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Close Icon */
.popup-close {
  position: absolute;
  top: 12px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 1.8rem;
  color: #555555;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.2s ease;
  z-index: 1060;
}

.popup-close:hover {
  color: #ff1744;
  transform: scale(1.1); /* Slight scaling on hover */
}

/* Popup Title */
.popup-container h2 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333333;
  margin-bottom: 20px;
}

/* Payment Details */
.payment-details {
  margin-bottom: 20px;
  text-align: left;
  background: #f9f9f9;
  padding: 10px;
  border-radius: 8px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle inset shadow */
}

.payment-details p {
  font-size: 1rem;
  color: #555555;
  margin: 5px 0;
}

.payment-details p strong {
  color: #333333;
}

/* Buttons */
.confirm-btn,
.close-btn {
  display: inline-block;
  margin: 10px 5px;
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.confirm-btn {
  background: #28a745;
  color: white;
}

.confirm-btn:hover {
  background: #218838;
  transform: scale(1.05); /* Slight scaling on hover */
}

.close-btn {
  background: #dc3545;
  color: white;
}

.close-btn:hover {
  background: #c82333;
  transform: scale(1.05); /* Slight scaling on hover */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .popup-container {
    width: 95%;
    padding: 15px;
  }

  .popup-container h2 {
    font-size: 1.5rem;
  }

  .confirm-btn,
  .close-btn {
    font-size: 0.9rem;
    padding: 10px 15px;
  }
}

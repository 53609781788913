/* Container for the entire form */
.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 5%;
}

/* Styling for the form */
.form {
  width: 100%;
  max-width: 90%;
  padding: 5%;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 2px solid #4A67E6; /* Blue branding border */
}

/* Form heading */
h2 {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.5rem;
  color: #4A67E6 !important; /* Blue branding color for headings */
}

/* Form group for input fields */
.formGroup {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

/* Label styling */
.form-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #FF0000 !important; /* Red branding color for labels */
}

/* Input and select styling */
input,
select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #4A67E6 !important; /* Blue branding border */
  border-radius: 4px;
  font-size: 1rem;
  background-color: #ffffff;
  color: #333;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  appearance: none;
  outline: none;
}

/* Focus effect for input and select */
input:focus,
select:focus {
  outline: none;
  border-color: #4A67E6; /* Focus branding color */
  box-shadow: 0 0 0 3px rgba(74, 103, 230, 0.2); /* Subtle blue glow for focus */
}

/* Icon styling */
.icon {
  width: 1rem;
  height: 1rem;
  color: #4A67E6 !important; /* Blue branding color for icons */
}

/* Submit button styling */
.submitButton {
  width: 100%;
  padding: 0.75rem;
  background: #FF0000 !important; /* Red branding color for button */
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Hover effect for the submit button */
.submitButton:hover {
  background-color: #B30000 !important; /* Darker red for hover */
}

/* Focus effect for the submit button */
.submitButton:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.5) !important; /* Red branding glow for focus */
}

/* Error Message */
.errorMessage {
  color: #FF0000; /* Bright red for error messages */
  font-size: 0.875rem;
  margin-bottom: 1rem;
  text-align: center;
}

/* Media Queries */

/* Small devices (min-width: 640px) */
@media (min-width: 640px) {
  .form {
    max-width: 80%;
    padding: 4%;
  }
}

/* Medium devices (min-width: 768px) */
@media (min-width: 768px) {
  .form {
    max-width: 70%;
    padding: 3%;
  }
}

/* Large devices (min-width: 1024px) */
@media (min-width: 1024px) {
  .form {
    max-width: 60%;
  }
}

/* Extra large devices (min-width: 1280px) */
@media (min-width: 1280px) {
  .form {
    max-width: 50%;
  }
}

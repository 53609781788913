/* Booking Container */
.booking-container {
  background-color: #ffffff; /* Bright white background */
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  padding: 20px 30px;
  max-width: 900px;
  margin: 40px auto;
  font-family: 'Arial', sans-serif;
  color: #2c3e50; /* Dark text for better readability */
}

/* Header */
.booking-header {
  text-align: center;
  color: #007bff; /* Vibrant blue */
  margin-bottom: 30px;
}

.booking-header h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.booking-header p {
  font-size: 1.2rem;
  color: #34495e; /* Slightly muted but contrasting text */
}

/* Sections */
.booking-section {
  margin-bottom: 25px;
}

.booking-section h2 {
  color: #1abc9c; /* Vibrant green */
  font-size: 1.8rem;
  margin-bottom: 15px;
  border-bottom: 2px solid #1abc9c;
  padding-bottom: 5px;
}

/* Card */
.card {
  background-color: #f8f9fa; /* Light gray background for contrast */
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 10px;
}

.card p {
  margin: 8px 0;
  font-size: 1rem;
  color: #2c3e50; /* Dark text for readability */
}

/* Payment Details */
.payment-details {
  background-color: #e9f7ef;
  border-left: 4px solid #28a745; /* Green border */
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  font-size: 1rem;
  color: #2c3e50; /* Contrasting dark text */
}

.payment-details strong {
  color: #28a745; /* Emphasized green for key info */
}

/* Essential Information */
.essential-info {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #eaf2fa; /* Light blue background */
  border-left: 4px solid #007bff; /* Blue accent border */
  border-radius: 5px;
  font-size: 1rem;
  line-height: 1.6;
  color: #2c3e50; /* Dark contrasting text */
}

/* Priority Numbers */
.priority-numbers p {
  margin: 10px 0;
  font-size: 1rem;
  color: #2c3e50; /* Clear and dark text */
}

/* Buttons */
.booking-container button {
  display: inline-block;
  background-color: #007bff; /* Vibrant blue button */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 15px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.booking-container button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Responsive */
@media (max-width: 768px) {
  .booking-container {
    padding: 20px;
  }

  .booking-header h1 {
    font-size: 2rem;
  }

  .booking-section h2 {
    font-size: 1.5rem;
  }

  .card p {
    font-size: 0.9rem;
  }
}

/* Container */
.booking-listing-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    font-family: Arial, sans-serif;
  }
  
  .booking-listing-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #333;
  }
  
  .no-bookings {
    text-align: center;
    font-size: 1.1rem;
    color: #555;
  }
  
  /* Table */
  .booking-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .booking-table th,
  .booking-table td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ddd;
    font-size: 0.9rem;
  }
  
  .booking-table th {
    background-color: #f4f4f4;
    font-weight: bold;
    color: #555;
  }
  
  .booking-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .booking-table tr:hover {
    background-color: #f1f1f1;
  }
  
  /* Status Badge */
  .status-badge {
    display: inline-block;
    padding: 5px 10px;
    font-size: 0.8rem;
    font-weight: bold;
    color: #fff;
    border-radius: 5px;
  }
  
  .status-badge.completed {
    background-color: #4caf50;
  }
  
  .status-badge.pending {
    background-color: #ff9800;
  }
  
  .status-badge.cancelled {
    background-color: #f44336;
  }
  
  /* Button */
  .view-button {
    padding: 8px 12px;
    font-size: 0.9rem;
    color: #fff;
    background-color: #4caf50;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .view-button:hover {
    background-color: #388e3c;
  }
  
  /* Pagination */
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 5px;
  }
  
  .pagination-button {
    padding: 8px 12px;
    font-size: 0.9rem;
    color: #333;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .pagination-button.active,
  .pagination-button:hover {
    background-color: #4caf50;
    color: #fff;
    border-color: #4caf50;
  }
  
  .pagination-button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  
  
/* General Styles */
body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
    color: #333;
  }
  
  .travel-details {
    width: 90%;
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .travel-item {
    border-bottom: 1px solid #ddd;
    padding: 20px 0;
  }
  
  .travel-item:last-child {
    border-bottom: none;
  }
  
  h3, h4 {
    color: #444;
    margin-bottom: 10px;
    font-size: 1.2rem;
  }
  
  p {
    margin: 5px 0;
  }
  
  strong {
    color: #555;
  }
  
  /* Sections */
  .travel-details-section,
  .vehicle-section,
  .route-section,
  .pickup-instructions {
    margin-bottom: 20px;
  }
  
  .travel-details-section h4,
  .vehicle-section h4,
  .route-section h4,
  .pickup-instructions h4 {
    color: #007bff;
    font-size: 1rem;
    border-bottom: 2px solid #007bff;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  
  /* Vehicle Details */
  .vehicle-section p {
    margin: 4px 0;
    color: #666;
  }
  
  .vehicle-section strong {
    color: #333;
  }
  
  /* Route Details */
  .route-section p {
    color: #666;
  }
  
  /* Pickup Instructions */
  .pickup-instructions div {
    background-color: #f1f9ff;
    padding: 15px;
    border-radius: 6px;
    font-size: 0.95rem;
  }
  
  .pickup-instructions b {
    color: #007bff;
  }
  
  .pickup-instructions strong {
    color: #d9534f;
  }
  
  /* Buttons and Links */
  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
/* Section Wrapper */
.contact-us-section {
  background: linear-gradient(to bottom, #ffffff, #e0e8f9); /* White to light blue gradient */
  padding: 40px 20px;
  font-family: "Roboto", sans-serif;
}

/* Card Styling */
.card {
  background: #ffffff; /* Clean white background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Gradient Text Styling */
.contact-title {
  background: linear-gradient(90deg, #4A67E6, #FF0000); /* Updated branding gradient */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-transform: uppercase; /* Optional for branding */
}

/* Map Container */
.map-container {
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.map-container iframe {
  border: none;
  border-radius: 8px;
  width: 100%;
  height: 100%;
}

/* Primary Text Styling */
.text-primary {
  color: #4A67E6; /* Blue for primary text */
  -webkit-text-fill-color: #4A67E6;
}

/* Secondary Text Styling */
.text-secondary {
  color: #FF0000 !important; /* Red for secondary text */
  -webkit-text-fill-color: #FF0000;
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-item {
      font-size: 0.9rem;
  }
}

/* Full Page Background */
.sign-up-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
  position: relative;
  overflow: hidden;
}

/* Modern Form Container */
.form-container.custom-sign-in.modern-effect {
  padding: 50px; /* Increased padding for larger width */
  width: 400px; /* Increased form width */
  max-width: 100%;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1), 0px 5px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.form-container.modern-effect:hover {
  transform: translateY(-10px); /* Slight floating effect on hover */
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2), 0px 10px 20px rgba(0, 0, 0, 0.1);
}

/* Form Header */
.custom-sign-in h2 {
  color: #4A67E6; /* Branding blue */
  text-align: center;
  margin-bottom: 30px;
  font-weight: bold;
}

/* Floating Labels for Custom Sign-In */
.custom-sign-in .form-group {
  position: relative;
  margin-bottom: 30px;
}

.custom-sign-in .form-input {
  width: 100%;
  padding: 12px 10px;
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.9); /* Subtle background */
  color: #333;
  outline: none;
  transition: all 0.3s ease;
}

.custom-sign-in .form-input:focus {
  border-color: #4A67E6; /* Branding blue */
  box-shadow: 0px 4px 8px rgba(74, 103, 230, 0.3); /* Blue glow */
}

.custom-sign-in .form-label {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 16px;
  color: #777;
  pointer-events: none;
  transition: all 0.3s ease;
}

.custom-sign-in .form-input:focus ~ .form-label,
.custom-sign-in .form-input:not(:placeholder-shown) ~ .form-label {
  top: -10px;
  left: 10px;
  font-size: 14px;
  color: #4A67E6; /* Matches focus border */
}

/* Error Message */
.custom-sign-in .form-error {
  color: #FF0000; /* Branding red for errors */
  font-size: 14px;
  margin-bottom: 10px;
  text-align: left;
}

/* Enhanced Button Styling */
.custom-sign-in .form-button {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  background: #FF0000 !important; /* Branding red */
  color: white;
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
}

.custom-sign-in .form-button:hover {
  background: #cc0000; /* Darker red for hover */
  transform: scale(1.05);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .form-container.custom-sign-in {
    padding: 30px;
    width: 90%;
  }
}

.link-div {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4A67E6; /* Branding blue */
  color: #fff;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-family: Arial, sans-serif;
  transition: background-color 0.3s ease;
}

.link-div:hover {
  background-color: #3A57D0; /* Slightly darker blue */
}

.link-div:active {
  background-color: #2F47B0; /* Even darker blue for active state */
}

/* Header Navbar Background */
.navbar {
  background-color: #ffffff !important; /* White background for header */
  color: #4A67E6 !important; /* Darker blue text for branding */
}

/* Navbar Links */
.nav-link {
  color: #4A67E6 !important; /* Darker blue text for links */
  text-decoration: none !important;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #FF0000 !important; /* Red on hover */
  text-decoration: underline !important; /* Subtle underline on hover */
}

/* Booking Link (CTA Button) */
.nav-link.booking {
  background-color: #4A67E6 !important; /* Darker blue background */
  color: #ffffff !important; /* White text */
  border-radius: 4px;
  padding: 8px 16px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-link.booking:hover {
  background-color: #FF0000 !important; /* Red background on hover */
  color: #ffffff !important; /* White text */
}

/* Navbar Brand */
.navbar-brand {
  text-decoration: none !important;
  color: #4A67E6 !important; /* Darker blue branding */
  font-weight: bold;
  transition: color 0.3s ease;
}

.navbar-brand:hover {
  color: #FF0000 !important; /* Red on hover */
}

/* Dark Button */
.btn-dark {
  background-color: #FF0000 !important; /* Red button */
  color: #ffffff !important; /* White text */
  border: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn-dark:hover {
  background-color: #B30000 !important; /* Darker shade of red on hover */
  color: #ffffff !important;
}

/* Outline Button */
.btn-outline-dark {
  background-color: transparent !important;
  color: #4A67E6 !important; /* Darker blue text */
  border: 2px solid #4A67E6 !important; /* Darker blue border */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn-outline-dark:hover {
  background-color: #4A67E6 !important; /* Darker blue background on hover */
  color: #ffffff !important; /* White text */
}

/* Book Now Button */
.nav-link.booknow {
  background-color: #FF0000 !important; /* Red background */
  color: #ffffff !important; /* White text */
  border-radius: 5px;
  padding: 5px 10px;
  text-decoration: none;
}

.nav-link.booknow:hover {
  background-color: #B30000 !important; /* Darker red on hover */
  color: #ffffff !important;
}

/* Booking Form Container */
.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}



/* Booking Form */
.booking-form {
  width: 100%;
  max-width: 600px;
  padding: 40px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 16px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  transition: transform 0.4s cubic-bezier(0.4, 0.0, 0.2, 1), 
              box-shadow 0.4s cubic-bezier(0.4, 0.0, 0.2, 1); /* Smooth easing */
}

/* Form Hover Effect */
.booking-form:hover {
  transform: translateY(-12px); /* Slightly higher lift */
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.2); /* Softer, expanded shadow */
}

/* Input Wrapper */
.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.inputWrapper .icon {
  position: absolute;
  left: 10px;
  font-size: 1.5rem;
  color: #3498db;
}

.inputWrapper .form-input {
  width: 100%;
  padding: 12px 12px 12px 40px; /* Adjust padding for icon space */
  border: 1px solid #d1d5db;
  border-radius: 8px;
  font-size: 1rem;
  background-color: #fff;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.inputWrapper .form-input:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 5px rgba(59, 130, 246, 0.5);
  outline: none;
}

/* Form Labels */
.form-label {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: #4b5563;
}

/* Submit Button */
.submitButton {
  width: 100%;
  padding: 12px;
  margin-top: 1rem;
  background: linear-gradient(to right, #3498db, #1d7bb8);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.submitButton:hover {
  transform: scale(1.05);
}

/* Error Message */
.errorMessage {
  color: #dc2626;
  font-size: 0.875rem;
  text-align: center;
}

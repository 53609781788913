/* Full Page Background */
.sign-up-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
  position: relative;
  overflow: hidden;
}

/* Form Container */
.custom-sign-in {
  width: 400px;
  padding: 40px;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1), 0px 5px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.custom-sign-in:hover {
  transform: translateY(-10px);
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2), 0px 10px 20px rgba(0, 0, 0, 0.1);
}

/* Floating Labels */
.custom-sign-in .form-group {
  position: relative;
  margin-bottom: 30px;
}

.custom-sign-in .form-input {
  width: 100%;
  padding: 12px 10px;
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.9);
  color: #333;
  outline: none;
  transition: all 0.3s ease;
}

.custom-sign-in .form-input:focus {
  border-color: #3498db;
  box-shadow: 0px 4px 8px rgba(52, 152, 219, 0.3);
}

.custom-sign-in .form-label {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 16px;
  color: #777;
  pointer-events: none;
  transition: all 0.3s ease;
  background: linear-gradient(to right, #3498db, #8e44ad);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.custom-sign-in .form-input:focus ~ .form-label,
.custom-sign-in .form-input:not(:placeholder-shown) ~ .form-label {
  top: -10px;
  left: 10px;
  font-size: 14px;
  color: #3498db;
  -webkit-text-fill-color: none;
}

/* Error Message */
.custom-sign-in .form-error {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
}

/* Submit Button */
.custom-sign-in .form-button {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  background: linear-gradient(to right, #3498db, #1d7bb8);
  color: #fff;
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
}

.custom-sign-in .form-button:hover {
  background: linear-gradient(to right, #1d7bb8, #3498db);
  transform: scale(1.05);
}

.custom-sign-in .form-button:active {
  transform: scale(0.95);
}

/* Loading Spinner */
.custom-sign-in .form-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.custom-sign-in .spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

/* Keyframes for Spinner */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

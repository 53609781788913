/* Container */
.failure-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full-screen height */
    text-align: center;
    background-color: #f9f9f9; /* Light background */
    padding: 20px;
  }
  
  /* Icon */
  .failure-icon {
    font-size: 4rem;
    color: #dc3545; /* Red color for failure */
    margin-bottom: 1rem;
  }
  
  /* Title */
  .failure-title {
    font-size: 2.5rem;
    color: #333333;
    margin-bottom: 1rem;
  }
  
  /* Message */
  .failure-message {
    font-size: 1.2rem;
    color: #555555;
    margin-bottom: 2rem;
    line-height: 1.6;
  }
  
  /* Button */
  .failure-button {
    padding: 12px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    background-color: #007bff; /* Blue button */
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .failure-button:hover {
    background-color: #0056b3;
    transform: scale(1.05); /* Slight scaling effect */
  }
  
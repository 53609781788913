/* Cab List Container */
.cabListContainer {
  background: linear-gradient(145deg, #f2f4f8, #ffffff);
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

/* Header */
.cabListContainer h4 {
  text-align: center;
  color: #4A67E6; /* Branding color (blue) */
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 25px;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

/* Cab List */
.cabList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column; /* Single column layout */
  gap: 20px; /* Consistent spacing between items */
}

/* Cab Item */
.cabItem {
  display: flex;
  flex-direction: column; /* Default to column for all devices */
  align-items: center; /* Center-align content */
  background: linear-gradient(145deg, #ffffff, #e0e5fc); /* Light blue gradient */
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cabItem:hover {
  transform: translateY(-7px);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.15);
}

/* Ensure column layout for mobile */
@media (max-width: 768px) {
  .cabItem {
    flex-direction: column; /* Vertical layout for mobile */
    text-align: center;
  }

  .cabDetails {
    margin-left: 0; /* Remove space for vertical alignment */
    margin-top: 15px; /* Add spacing above the details */
  }
}

/* Desktop and Tablet Layout */
@media (min-width: 769px) {
  .cabItem {
    flex-direction: row; /* Horizontal layout for larger screens */
    align-items: center; /* Align items in the center */
  }

  .cabDetails {
    margin-left: 20px; /* Space between image and details */
  }
}

/* Cab Image Container */
.cabImageContainer {
  flex: 0 0 150px; /* Fixed width for the image */
  height: 120px; /* Fixed height */
  background: #e0e5fc; /* Light blue background */
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cabImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease, filter 0.3s ease;
}

.cabImageContainer:hover .cabImage {
  transform: scale(1.2);
  filter: brightness(1.1);
}

/* Cab Details */
.cabDetails {
  flex-grow: 1;
  text-align: left;
}

.cabName {
  font-size: 1.5rem;
  font-weight: bold;
  color: #4A67E6; /* Branding color (blue) */
  margin-bottom: 5px;
}

.cabType {
  font-size: 1rem;
  color: #FF0000; /* Branding color (red) */
  margin-bottom: 8px;
}

.cabFare {
  font-size: 1.3rem;
  color: #28a745; /* Green for fare */
  font-weight: bold;
}

.cabSeats,
.cabDescription {
  font-size: 0.95rem;
  color: #555555;
  margin-top: 3px;
}

/* Select Button */
.selectCabButton {
  background: #4A67E6; /* Solid blue for branding */
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 20px;
  cursor: pointer;
  font-size: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  margin-top: 15px; /* Add spacing above the button */
}

.selectCabButton:hover {
  background: #FF0000; /* Red on hover for strong CTA */
  transform: scale(1.1);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.error-message {
  color: #FF0000; /* Branding red */
  font-size: 0.875em;
  margin-top: 5px;
}

.contact-us-container {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff; /* White background */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #4A67E6; /* Branding blue */
  font-weight: bold;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
  color: #4A67E6; /* Branding blue */
}

input,
textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #4A67E6; /* Branding blue border */
  border-radius: 5px;
}

input:focus,
textarea:focus {
  outline: none;
  border-color: #FF0000; /* Branding red on focus */
  box-shadow: 0 0 5px rgba(255, 0, 0, 0.5); /* Subtle red glow */
}

textarea {
  resize: vertical;
}

.submit-button {
  background-color: #FF0000; /* Branding red */
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.submit-button:hover {
  background-color: #CC0000; /* Darker red */
  transform: scale(1.05); /* Slight hover effect */
}

.success-message {
  text-align: center;
  color: #28a745; /* Green for success */
  font-weight: bold;
  margin-bottom: 20px;
}

/* About Section */
.about-us-section {
    background: linear-gradient(to bottom, #ffffff, #e0e8f9); /* White to light blue gradient */
    padding: 40px 20px;
    font-family: "Roboto", sans-serif;
}

/* Main Titles */
.about-title {
    font-size: 2rem;
    font-weight: bold;
    color: #4A67E6 !important; /* Blue branding color */
    text-transform: uppercase;
    background: none !important;
    -webkit-text-fill-color: #4A67E6;
}

.about-subtitle {
    font-size: 1.5rem;
    font-weight: 600;
    color: #FF0000 !important; /* Red for emphasis */
}

/* Paragraph Text */
.about-text {
    font-size: 1rem;
    color: #4d4d4d; /* Neutral dark gray for readability */
    line-height: 1.8;
}

/* Image */
.about-image {
    max-width: 600px;
    height: auto;
    border: 3px solid #4A67E6; /* Blue border for branding */
    border-radius: 8px; /* Slightly rounded corners */
}

/* Features Section */
.feature-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #FF0000; /* Red for feature titles */
}

.feature-text {
    font-size: 1rem;
    color: #4d4d4d; /* Neutral gray for readability */
    line-height: 1.6;
}

/* Cards */
.card {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for modern look */
    padding: 20px;
}

.card:hover {
    transform: scale(1.02); /* Slight scale effect on hover */
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15); /* More pronounced shadow on hover */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}
